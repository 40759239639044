import { useContext, useEffect } from "react";
import { AppContext, set_IntlObj } from "../context";
import enMessages from '../i18n/messages/en-GB.json';
import deMessages from '../i18n/messages/de-DE.json';
import esMessages from '../i18n/messages/es-ES.json';
import frMessages from '../i18n/messages/fr-FR.json';
import jaMessages from '../i18n/messages/ja-JP.json';
import zhMessages from '../i18n/messages/zh-CN.json';
import { GETreq } from "../services";
import { de_DE, en_GB, es_ES, fr_FR, ja_JP, zh_CN } from "../utils/constant";

const allMessages = { "en-GB": enMessages, "de-DE": deMessages, "es-ES": esMessages, "fr-FR": frMessages, "ja-JP": jaMessages, "zh-CN": zhMessages };
// const allMessages = { "en-GB": jaMessages, "de-DE": zhMessages, "es-ES": enMessages, "fr-FR": deMessages, "ja-JP": esMessages, "zh-CN": frMessages };

function useI18nMessages() {
    const { state: { loadingIntl, intlObj, appConfig: { OCP_APIM_SUBSCRIPTION_KEY } }, dispatch } = useContext(AppContext);

    async function GetIntlObj() {
        // await Promise.all([GETreq(lng("en-GB"), undefined, undefined, undefined, lng("en-GB")), GETreq(lng("de-DE"), undefined, undefined, undefined, lng("de-DE")), GETreq(lng("es-ES"), undefined, undefined, undefined, lng("es-ES")), GETreq(lng("fr-FR"), undefined, undefined, undefined, lng("fr-FR")), GETreq(lng("ja-JP"), undefined, undefined, undefined, lng("ja-JP")), GETreq(lng("zh-CN"), undefined, undefined, undefined, lng("zh-CN"))]).
        await Promise.all([
            GETreq("en-GB", OCP_APIM_SUBSCRIPTION_KEY, '', false, undefined, en_GB),
            GETreq("de-DE", OCP_APIM_SUBSCRIPTION_KEY, '', false, undefined, de_DE),
            GETreq("es-ES", OCP_APIM_SUBSCRIPTION_KEY, '', false, undefined, es_ES),
            GETreq("fr-FR", OCP_APIM_SUBSCRIPTION_KEY, '', false, undefined, fr_FR),
            GETreq("ja-JP", OCP_APIM_SUBSCRIPTION_KEY, '', false, undefined, ja_JP),
            GETreq("zh-CN", OCP_APIM_SUBSCRIPTION_KEY, '', false, undefined, zh_CN)
        ]).then((
            [{ res: enRes }, { res: deRes }, { res: esRes }, { res: frRes }, { res: jaRes }, { res: zhRes }]) => {
            const intlResObj = { "en-GB": enRes, "de-DE": deRes, "es-ES": esRes, "fr-FR": frRes, "ja-JP": jaRes, "zh-CN": zhRes };
            set_IntlObj(dispatch, intlResObj);
        }).catch((err) => { console.log(err); });
    };

    useEffect(() => {
        /*  setTimeout(() => {
             if (loadingIntl) { set_IntlObj(dispatch, allMessages); }
         }, 2000); */
         if (loadingIntl) { set_IntlObj(dispatch, allMessages); }
         //GetIntlObj();
        // else { console.log("Intl already loaded"); }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingIntl, allMessages, dispatch]);
    return { loadingIntl, intlObj };
};

export default useI18nMessages;