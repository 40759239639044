import { AppContextProvider } from './AppContextProvider';
import { createContext } from 'react';
import { IAppContext } from '../types';
export * from './action';

const AppContext = createContext<IAppContext>({
    state: {
        _User: {
            name: "",
            email: "",
            token: "",
            lastUpdated: new Date().toUTCString(),
        },
        appConfig: {
            'LOGIN_LOGOUT_REDIRECT_URL': "",
            'MSAL_AZURE_SIGNIN_AUTHORITY': "",
            'MSAL_AZUREAD_CLIENT_ID': "",
            'SOPAPLURL': "",
            'RM_BASEURL': "",
            'OCP_APIM_SUBSCRIPTION_KEY': "",
            loading: true,
        },
        isLoading: true,
        intlObj: {
            "en-GB": {},
            "de-DE": {},
            "es-ES": {},
            "fr-FR": {},
            "ja-JP": {},
            "zh-CN": {}
        },
        loadingIntl: true
    },
    dispatch: () => { }
});
export { AppContext, AppContextProvider };